// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-rate-js": () => import("./../../../src/pages/add-rate.js" /* webpackChunkName: "component---src-pages-add-rate-js" */),
  "component---src-pages-faq-html-js": () => import("./../../../src/pages/faq.html.js" /* webpackChunkName: "component---src-pages-faq-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-style-js": () => import("./../../../src/pages/indexStyle.js" /* webpackChunkName: "component---src-pages-index-style-js" */),
  "component---src-pages-kontakt-html-js": () => import("./../../../src/pages/kontakt.html.js" /* webpackChunkName: "component---src-pages-kontakt-html-js" */),
  "component---src-pages-marka-brother-html-js": () => import("./../../../src/pages/marka,brother.html.js" /* webpackChunkName: "component---src-pages-marka-brother-html-js" */),
  "component---src-pages-marka-canon-html-js": () => import("./../../../src/pages/marka,canon.html.js" /* webpackChunkName: "component---src-pages-marka-canon-html-js" */),
  "component---src-pages-marka-hp-html-js": () => import("./../../../src/pages/marka,hp.html.js" /* webpackChunkName: "component---src-pages-marka-hp-html-js" */),
  "component---src-pages-marka-minolta-html-js": () => import("./../../../src/pages/marka,minolta.html.js" /* webpackChunkName: "component---src-pages-marka-minolta-html-js" */),
  "component---src-pages-marka-samsung-html-js": () => import("./../../../src/pages/marka,samsung.html.js" /* webpackChunkName: "component---src-pages-marka-samsung-html-js" */),
  "component---src-pages-marka-xerox-html-js": () => import("./../../../src/pages/marka,xerox.html.js" /* webpackChunkName: "component---src-pages-marka-xerox-html-js" */),
  "component---src-pages-marki-html-js": () => import("./../../../src/pages/marki.html.js" /* webpackChunkName: "component---src-pages-marki-html-js" */),
  "component---src-pages-opinie-html-js": () => import("./../../../src/pages/opinie.html.js" /* webpackChunkName: "component---src-pages-opinie-html-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-sprawdz-wplate-html-js": () => import("./../../../src/pages/sprawdz_wplate.html.js" /* webpackChunkName: "component---src-pages-sprawdz-wplate-html-js" */)
}

